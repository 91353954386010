import 'saas-react-hoc/dist/index.css';
import 'framework-react-ccs/dist/styles/index.css';

import { lazy } from 'react';
import { ActionApplications, bootstrap } from 'saas-react-hoc';

import en_US from './locales/en_US.json';
import pt_BR from './locales/pt_BR.json';
import es from './locales/es.json';

import routes from './routes';

import pkg from '../package.json';

import logo from './assets/images/consistem-logo.png';

const App = lazy(() => import('./App').then(App => ({ default: App.default })));
const Home = lazy(() => import('./pages/Home').then(Home => ({ default: Home.default })));

export default bootstrap(App, Home, {
    appVersion: pkg.version,
    router: {
        routes,
    },
    showOrganiationSelector: false,
    locales: [
        { language: 'en_US', resource: en_US },
        { language: 'pt_BR', resource: pt_BR },
        { language: 'es', resource: es },
    ],
    topbar: {
        enableOrganizations: false,
        enableCompanies: false,
        menu: [
            {
                icon: 'industry',
                name: 'Organizations',
                route: '/organizations',
            },
            {
                icon: 'th',
                name: 'Applications',
                route: '/applications',
            },
            {
                icon: 'users',
                name: 'Users Settings',
                items: [
                    {
                        key: 'users',
                        name: 'Users',
                        route: '/users',
                    },
                    {
                        key: 'roles',
                        name: 'Users roles',
                        route: '/users/roles',
                    },
                    {
                        key: 'preferences',
                        name: 'Users preferences',
                        route: '/users/preferences',
                    },
                    {
                        key: 'pending_invitations',
                        name: 'Pending invitations',
                        route: '/users/pendingInvitations',
                    },
                ],
            },
            {
                icon: 'cog',
                name: 'Settings',
                items: [
                    {
                        key: 'ldap',
                        name: 'LDAP',
                        route: '/settings/ldap',
                    },
                ],
            },
        ],
        actions: {
            right: [<ActionApplications key='action_applications' />],
        },
        title: {
            name: 'Administrativo',
            icon: logo,
        },
    },
});
