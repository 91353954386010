import { lazy } from 'react';

const User = lazy(() => import('./pages/User/User'));
const UserRoles = lazy(() => import('./pages/User/UserRoles'));
const Preferences = lazy(() => import('./pages/User/Preferences'));
const Roles = lazy(() => import('./pages/User/Roles'));
const PendingInvitations = lazy(() => import('./pages/User/PendingInvitations'));
const Variables = lazy(() => import('./pages/Variables/Variables'));
const Organization = lazy(() => import('./pages/Organization/Organization'));
const OrganizationCompany = lazy(() => import('./pages/Organization/Companies/Companies'));
const OrganizationApplicationsStatus = lazy(() => import('./pages/Organization/ApplicationsStatus'));
const OrganizationServerOnPremise = lazy(() => import('./pages/Organization/ServersOnPremise'));
const OrganizationCompanyUsers = lazy(() => import('./pages/Organization/Companies/Users/Users'));
const OrganizationUsersApplications = lazy(() => import('./pages/Organization/UsersApplications'));
const OrganizationCompanyUserApplications = lazy(() => import('./pages/Organization/Companies/Users/UserApplications'));
const OrganizationApplications = lazy(() => import('./pages/Organization/Applications'));
const Application = lazy(() => import('./pages/Application/Application'));
const ApplicationMenu = lazy(() => import('./pages/Application/menu/Menu'));
const ApplicationEntity = lazy(() => import('./pages/Application/entity/Entity'));
const ApplicationEntityAction = lazy(() => import('./pages/Application/entity/Action'));
const ApplicationPipelinesRuns = lazy(() => import('./pages/Application/PipelinesRuns'));
const Workflow = lazy(() => import('./pages/workflow/Workflow'));
const Kernel = lazy(() => import('./pages/Kernel'));
const Ldap = lazy(() => import('./pages/Settings/LDAP/Ldap'));

const routes = [
    {
        path: 'organizations',
        title: 'Organizations',
        placeholder: 'table',
        element: Organization,
        children: [
            {
                path: ':orgId/companies',
                title: 'Companies',
                placeholder: 'table',
                element: OrganizationCompany,
                children: [
                    {
                        path: ':companyId/users',
                        title: 'Users',
                        placeholder: 'table',
                        element: OrganizationCompanyUsers,
                        children: [
                            {
                                path: ':userId/:orgUserId/userApplications',
                                title: 'Applications',
                                placeholder: 'table',
                                element: OrganizationCompanyUserApplications,
                            },
                        ],
                    },
                ],
            },
            {
                path: ':orgId/applicationStatus',
                title: 'Application Status',
                placeholder: 'table',
                element: OrganizationApplicationsStatus,
            },
            {
                path: ':orgId/serversOnPremise',
                title: 'Servers On Premise',
                placeholder: 'table',
                element: OrganizationServerOnPremise,
            },
            {
                path: ':orgId/applications',
                title: 'Applications',
                placeholder: 'table',
                element: OrganizationApplications,
            },
            {
                path: ':orgId/usersApplications',
                title: 'Users Applications',
                placeholder: 'table',
                element: OrganizationUsersApplications,
            },
        ],
    },
    {
        path: 'applications',
        title: 'Applications',
        placeholder: 'table',
        element: Application,
        children: [
            {
                path: ':appId/menus',
                title: 'Menu',
                placeholder: 'table',
                element: ApplicationMenu,
            },
            {
                path: ':appId/entities',
                title: 'Entities',
                placeholder: 'table',
                element: ApplicationEntity,
                children: [
                    {
                        path: ':entityId/actions',
                        title: 'Actions',
                        placeholder: 'table',
                        element: ApplicationEntityAction,
                    },
                ],
            },
        ],
    },
    {
        path: 'applications/pipelinesRuns',
        title: 'Pipelines Runs',
        placeholder: 'table',
        element: ApplicationPipelinesRuns,
    },
    {
        path: 'users',
        title: 'Users',
        placeholder: 'table',
        element: User,
        children: [
            {
                path: ':userId/roles',
                title: 'User Roles',
                placeholder: 'table',
                element: UserRoles,
            },
        ],
    },
    {
        path: 'users/roles',
        title: 'Users Roles',
        placeholder: 'table',
        element: Roles,
    },
    {
        path: 'users/preferences',
        title: 'Users Preferences',
        placeholder: 'table',
        element: Preferences,
    },
    {
        path: 'users/pendingInvitations',
        title: 'Pending invitations',
        placeholder: 'table',
        element: PendingInvitations,
    },
    {
        path: 'variables',
        title: 'Variables',
        placeholder: 'table',
        element: Variables,
    },
    {
        path: 'settings/ldap',
        title: 'LDAP',
        placeholder: 'table',
        element: Ldap,
    },
    {
        path: 'workflow',
        title: 'Workflow',
        placeholder: 'table',
        element: Workflow,
        children: [
            {
                path: ':filterId',
                title: 'Workflow',
                placeholder: 'table',
                element: Workflow,
                children: [
                    {
                        path: ':workflowId',
                        title: 'Workflow',
                        placeholder: 'table',
                        element: Workflow,
                    },
                ],
            },
        ],
    },
    {
        path: 'kernel',
        title: 'Kernel',
        placeholder: 'table',
        element: Kernel,
    },
];

export default routes;
