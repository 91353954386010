import './assets/css/index.css';
import 'semantic-styles-ccs/dist/index.css';
import 'saas-react-hoc/dist/index.css';
import 'modal-react-ccs/dist/styles/index.css';

import { createRoot } from 'react-dom/client';

import reportWebVitals from './reportWebVitals';

import Boot from './Boot';

const APP_ID = process.env.REACT_APP_ID;

const container = document.getElementById('root');
const root = createRoot(container);

root.render(<Boot appId={APP_ID || 'admin'} />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals((...args) => {
    if ('development'.startsWith(process.env.NODE_ENV)) {
        // console.log(...args);
    }
});
